import { generateClasses } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
import genesis from '@formkit/themes/tailwindcss/genesis'
import { autocomplete, createProPlugin, dropdown, mask, rating, toggle } from '@formkit/pro'
import { createAutoAnimatePlugin, createMultiStepPlugin } from '@formkit/addons'

const proPlugin = createProPlugin('fk-1f12e526b9', {
  rating,
  toggle,
  autocomplete,
  mask,
  dropdown,
  // ... and any other Pro Inputs
})

export default {
  icons: {
    ...genesisIcons,
  },
  config: {
    classes: generateClasses(genesis),
  },
  plugins: [
    proPlugin,
    createMultiStepPlugin(),
    createAutoAnimatePlugin({
      duration: 300,
      easing: 'ease-in-out',
      delay: 0,
    },
    {
      /* optional animation targets object */
      // default:
      global: ['outer', 'inner'],
      form: ['form'],
      repeater: ['items'],
    }),
  ],
}
