<script setup lang="ts">
// console.log('app.vue mounted!!')
// if (process.server)
//   console.log('server process')

useHead({
  meta: [
    { name: "description", content: "Business" },
    { property: "og:title", content: "QuoteIQ" },
    {
      property: "og:image",
      content:
        "https://d1aettbyeyfilo.cloudfront.net/fse1/28997065_1656101915VGLQUOTE_IQ_PNG_TRANSPARENT.webp",
    },
  ],
});
</script>

<template>
  <NuxtLayout>
    <!-- <SeoKit /> -->
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="postcss">
html,
body,
#__nuxt {
  height: 100vh;
  margin: 0;
  padding: 0;
}

html.dark {
  background: #222;
  color: white;
}
</style>
