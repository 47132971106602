import { default as _91_46_46_46all_93wNifDAWqZeMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/[...all].vue?macro=true";
import { default as _91id_93XNiAcEZEB6Meta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/customer_form/[id].vue?macro=true";
import { default as indexqlLvNkfmjSMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/customer_form/index.vue?macro=true";
import { default as _91id_93gBNZ4A97jmMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/estimates/[id].vue?macro=true";
import { default as indexmEWPZGXA45Meta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/estimates/index.vue?macro=true";
import { default as _91id_93vzlvvg1fcOMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/hi/[id].vue?macro=true";
import { default as indexdxyHRcLRRXMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/hi/index.vue?macro=true";
import { default as indexPT1VgwHAxUMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/index.vue?macro=true";
import { default as _91id_935QWc3MCTchMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/invoices/[id].vue?macro=true";
import { default as indexfWSm3pxH0NMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/invoices/index.vue?macro=true";
import { default as _91id_93sWnlofQl1XMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new_customer/[id].vue?macro=true";
import { default as indexeQxHKcM7xcMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new_customer/index.vue?macro=true";
import { default as _91id_93ulzXegsH2HMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new/[id].vue?macro=true";
import { default as indexT9qVojaSDzMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new/index.vue?macro=true";
import { default as policyOprb4WqquEMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/policy.vue?macro=true";
import { default as _91id_93U8EqSQ3TgOMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/schedule/[id].vue?macro=true";
import { default as indexZ9du28yncYMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/schedule/index.vue?macro=true";
import { default as termsodKP33kQvTMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/terms.vue?macro=true";
import { default as transfer_45failB6rrFZLytpMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/transfer-fail.vue?macro=true";
import { default as transfer_45success2YHGQQeVW9Meta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/transfer-success.vue?macro=true";
import { default as unsubscribep0eEfZyGkUMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/unsubscribe.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93wNifDAWqZeMeta?.name ?? "all",
    path: _91_46_46_46all_93wNifDAWqZeMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93wNifDAWqZeMeta || {},
    alias: _91_46_46_46all_93wNifDAWqZeMeta?.alias || [],
    redirect: _91_46_46_46all_93wNifDAWqZeMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XNiAcEZEB6Meta?.name ?? "customer_form-id",
    path: _91id_93XNiAcEZEB6Meta?.path ?? "/customer_form/:id()",
    meta: _91id_93XNiAcEZEB6Meta || {},
    alias: _91id_93XNiAcEZEB6Meta?.alias || [],
    redirect: _91id_93XNiAcEZEB6Meta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/customer_form/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqlLvNkfmjSMeta?.name ?? "customer_form",
    path: indexqlLvNkfmjSMeta?.path ?? "/customer_form",
    meta: indexqlLvNkfmjSMeta || {},
    alias: indexqlLvNkfmjSMeta?.alias || [],
    redirect: indexqlLvNkfmjSMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/customer_form/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gBNZ4A97jmMeta?.name ?? "estimates-id",
    path: _91id_93gBNZ4A97jmMeta?.path ?? "/estimates/:id()",
    meta: _91id_93gBNZ4A97jmMeta || {},
    alias: _91id_93gBNZ4A97jmMeta?.alias || [],
    redirect: _91id_93gBNZ4A97jmMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/estimates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmEWPZGXA45Meta?.name ?? "estimates",
    path: indexmEWPZGXA45Meta?.path ?? "/estimates",
    meta: indexmEWPZGXA45Meta || {},
    alias: indexmEWPZGXA45Meta?.alias || [],
    redirect: indexmEWPZGXA45Meta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/estimates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vzlvvg1fcOMeta?.name ?? "hi-id",
    path: _91id_93vzlvvg1fcOMeta?.path ?? "/hi/:id()",
    meta: _91id_93vzlvvg1fcOMeta || {},
    alias: _91id_93vzlvvg1fcOMeta?.alias || [],
    redirect: _91id_93vzlvvg1fcOMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/hi/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdxyHRcLRRXMeta?.name ?? "hi",
    path: indexdxyHRcLRRXMeta?.path ?? "/hi",
    meta: indexdxyHRcLRRXMeta || {},
    alias: indexdxyHRcLRRXMeta?.alias || [],
    redirect: indexdxyHRcLRRXMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/hi/index.vue").then(m => m.default || m)
  },
  {
    name: indexPT1VgwHAxUMeta?.name ?? "index",
    path: indexPT1VgwHAxUMeta?.path ?? "/",
    meta: indexPT1VgwHAxUMeta || {},
    alias: indexPT1VgwHAxUMeta?.alias || [],
    redirect: indexPT1VgwHAxUMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_935QWc3MCTchMeta?.name ?? "invoices-id",
    path: _91id_935QWc3MCTchMeta?.path ?? "/invoices/:id()",
    meta: _91id_935QWc3MCTchMeta || {},
    alias: _91id_935QWc3MCTchMeta?.alias || [],
    redirect: _91id_935QWc3MCTchMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfWSm3pxH0NMeta?.name ?? "invoices",
    path: indexfWSm3pxH0NMeta?.path ?? "/invoices",
    meta: indexfWSm3pxH0NMeta || {},
    alias: indexfWSm3pxH0NMeta?.alias || [],
    redirect: indexfWSm3pxH0NMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sWnlofQl1XMeta?.name ?? "new_customer-id",
    path: _91id_93sWnlofQl1XMeta?.path ?? "/new_customer/:id()",
    meta: _91id_93sWnlofQl1XMeta || {},
    alias: _91id_93sWnlofQl1XMeta?.alias || [],
    redirect: _91id_93sWnlofQl1XMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new_customer/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeQxHKcM7xcMeta?.name ?? "new_customer",
    path: indexeQxHKcM7xcMeta?.path ?? "/new_customer",
    meta: indexeQxHKcM7xcMeta || {},
    alias: indexeQxHKcM7xcMeta?.alias || [],
    redirect: indexeQxHKcM7xcMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new_customer/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ulzXegsH2HMeta?.name ?? "new-id",
    path: _91id_93ulzXegsH2HMeta?.path ?? "/new/:id()",
    meta: _91id_93ulzXegsH2HMeta || {},
    alias: _91id_93ulzXegsH2HMeta?.alias || [],
    redirect: _91id_93ulzXegsH2HMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new/[id].vue").then(m => m.default || m)
  },
  {
    name: indexT9qVojaSDzMeta?.name ?? "new",
    path: indexT9qVojaSDzMeta?.path ?? "/new",
    meta: indexT9qVojaSDzMeta || {},
    alias: indexT9qVojaSDzMeta?.alias || [],
    redirect: indexT9qVojaSDzMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new/index.vue").then(m => m.default || m)
  },
  {
    name: policyOprb4WqquEMeta?.name ?? "policy",
    path: policyOprb4WqquEMeta?.path ?? "/policy",
    meta: policyOprb4WqquEMeta || {},
    alias: policyOprb4WqquEMeta?.alias || [],
    redirect: policyOprb4WqquEMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93U8EqSQ3TgOMeta?.name ?? "schedule-id",
    path: _91id_93U8EqSQ3TgOMeta?.path ?? "/schedule/:id()",
    meta: _91id_93U8EqSQ3TgOMeta || {},
    alias: _91id_93U8EqSQ3TgOMeta?.alias || [],
    redirect: _91id_93U8EqSQ3TgOMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/schedule/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZ9du28yncYMeta?.name ?? "schedule",
    path: indexZ9du28yncYMeta?.path ?? "/schedule",
    meta: indexZ9du28yncYMeta || {},
    alias: indexZ9du28yncYMeta?.alias || [],
    redirect: indexZ9du28yncYMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: termsodKP33kQvTMeta?.name ?? "terms",
    path: termsodKP33kQvTMeta?.path ?? "/terms",
    meta: termsodKP33kQvTMeta || {},
    alias: termsodKP33kQvTMeta?.alias || [],
    redirect: termsodKP33kQvTMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: transfer_45failB6rrFZLytpMeta?.name ?? "transfer-fail",
    path: transfer_45failB6rrFZLytpMeta?.path ?? "/transfer-fail",
    meta: transfer_45failB6rrFZLytpMeta || {},
    alias: transfer_45failB6rrFZLytpMeta?.alias || [],
    redirect: transfer_45failB6rrFZLytpMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/transfer-fail.vue").then(m => m.default || m)
  },
  {
    name: transfer_45success2YHGQQeVW9Meta?.name ?? "transfer-success",
    path: transfer_45success2YHGQQeVW9Meta?.path ?? "/transfer-success",
    meta: transfer_45success2YHGQQeVW9Meta || {},
    alias: transfer_45success2YHGQQeVW9Meta?.alias || [],
    redirect: transfer_45success2YHGQQeVW9Meta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/transfer-success.vue").then(m => m.default || m)
  },
  {
    name: unsubscribep0eEfZyGkUMeta?.name ?? "unsubscribe",
    path: unsubscribep0eEfZyGkUMeta?.path ?? "/unsubscribe",
    meta: unsubscribep0eEfZyGkUMeta || {},
    alias: unsubscribep0eEfZyGkUMeta?.alias || [],
    redirect: unsubscribep0eEfZyGkUMeta?.redirect,
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/unsubscribe.vue").then(m => m.default || m)
  }
]