import revive_payload_client_0pfNLLXkPc from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_async-validator@4._bquetmuwypul42mz2jdm5s5way/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_x8z1hafcYL from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_async-validator@4._bquetmuwypul42mz2jdm5s5way/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FeUCePcdSL from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_async-validator@4._bquetmuwypul42mz2jdm5s5way/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_JPhgNK27sM from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_async-validator@4._bquetmuwypul42mz2jdm5s5way/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_mjPxjPhOWD from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_async-validator@4._bquetmuwypul42mz2jdm5s5way/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_mthfifUHcd from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_async-validator@4._bquetmuwypul42mz2jdm5s5way/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/formkitPlugin.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/element-plus-injection.plugin.mjs";
import plugin_client_51MdIzX6fK from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.17.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/unocss.mjs";
import chunk_reload_client_LbFBEQsTeK from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_async-validator@4._bquetmuwypul42mz2jdm5s5way/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_VpyRto7gjQ from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/@vueuse+nuxt@10.9.0_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59._4ukvvkylsovo2pzds3yvik5grq/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
export default [
  revive_payload_client_0pfNLLXkPc,
  unhead_x8z1hafcYL,
  router_FeUCePcdSL,
  payload_client_JPhgNK27sM,
  check_outdated_build_client_mjPxjPhOWD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mthfifUHcd,
  formkitPlugin_pZqjah0RUG,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_client_51MdIzX6fK,
  unocss_MzCDxu9LMj,
  chunk_reload_client_LbFBEQsTeK,
  ssr_plugin_VpyRto7gjQ
]